import React, { useState }from "react";
import { Parallax } from "react-parallax";
import Layout from "../../components/layouts";
import bgImage from "../../images/header-volunteer.jpg";

const SignUpVolunteer = () => {
  const [feedback, setFeedback] = useState({
    message: "",
    messageType: "",
  });
  const [email, setEmail] = useState("");
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [phone, setPhone] = useState("");
  const [skype, setSkype] = useState("");
  const [facebook, setFacebook] = useState("");
  const [resume, setResume] = useState("");
  const [source, setSource] = useState("");
  const [why, setWhy] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (fName !== '' && lName !== '' && email !== '' && facebook !== '') {
      const regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      const form = document.querySelector('form');
      const data = new FormData(form);

      if (!regex.test(email)) {
          setFeedback({
              message: 'Please enter a valid E-mail',
              messageType: 'error'
          });
          return;
      } else {
          fetch("https://globalinheritance.us2.list-manage.com/subscribe/post-json?&c=?", {
            method: "POST",
            body: data,
            mode: 'no-cors',
          }).then(async (response) => {
            console.log(response);
            setFeedback({
                message: 'Thank you! Your information has been sent',
                messageType: 'success'
            });
          });
        }
  } else {
      setFeedback({
          message: 'Please fill out all fields',
          messageType: 'error'
      });
  }
  };


  return (
    <Layout navBg>
      <div className="signup-page">
        <div className="signup-inner">
        <Parallax className="page-header" bgImage={bgImage} strength={400}>
              <div style={{height: '100vh', maxHeight: '550px'}}>
                  <div className="page-header-overlay" />
                  <h1 className="page-header-title white">Volunteer</h1>
              </div>
          </Parallax>
          <div className="signup-body">
        <div className="signup-intro">
            <p>Global Inheritance is looking for exceptional individuals who are ready to join us in producing large education programs focused on improving planetary health. Do you love the arts and using creativity to inspire others? Do you excel when the lights shine the brightest? If the answers are “yes” – please join our volunteer network and get involved!</p>

            <p>Global Inheritance is a collection of passionate, talented individuals working hard to create long term improvements across a range of issues effecting the planet.</p>

            <p>If you believe in what we do and are driven to find new ways to educate and empower others – sign up below!</p>
        </div>

        <div className="form-container application-form-container">
            <div className="form-inner application-form-inner">
                <h1>Join the Community Today!</h1>
                <p>*Required field</p>
                <form name="applicationForm" className="form application-form app-volunteer" onSubmit={(e) => handleSubmit(e)}>
                    <input type="hidden" name="u" value="40e700f186279051f3a238f8a"/>
                    <input type="hidden" name="id" value="83440ad4be"/>
                    <input type="hidden" name="TYPE" id="MERGE3" value="VOLUNTEER" />
                    <div className="form-field application-field">
                        <input type="text" name="FNAME" placeholder="First Name" id="MERGE1" value={fName} onChange={(e)=>setFname(e.target.value) }/>
                        <label>FIRST NAME*</label>
                    </div>
                    <div className="form-field application-field">
                        <input type="text" name="LNAME" placeholder="Last Name" id="MERGE11" value={lName} onChange={(e)=>setLname(e.target.value)}/>
                        <label>LAST NAME*</label>
                    </div>
                    <div className="form-field application-field">
                        <input type="email" name="EMAIL" placeholder="Email" id="MERGE0" value={email}  onChange={(e)=>setEmail(e.target.value)} />
                        <label>EMAIL*</label>
                    </div>
                    <div className="form-field application-field">
                        <input type="text" name="PHONE" placeholder="Phone" id="MERGE8" value={phone}  onChange={(e)=>setPhone(e.target.value)}/>
                        <label>PHONE NUMBER</label>
                    </div>
                    <div className="form-field application-field">
                        <input type="text" name="VIDEOCHAT" placeholder="Social" id="MERGE6" value={skype}  onChange={(e)=>setSkype(e.target.value) } />
                        <label>SKYPE/FACETIME ID</label>
                    </div>
                    <div className="form-field application-field">
                        <input type="text" name="FACEBOOK" id="MERGE1" placeholder="Social" value={facebook}  onChange={(e)=>setFacebook(e.target.value)} />
                        <label>INSTAGRAM (PUBLIC ACCOUNTS ONLY), FACEBOOK OR PHOTO URL*</label>
                    </div>
                    <div className="form-field application-field">
                        <input type="text" name="RESUME" id="MERGE7" placeholder="Resume" value={resume}  onChange={(e)=>setResume(e.target.value)} />
                        <label>LINK TO RESUME</label>
                    </div>
                    <div className="form-field application-field">
                        <input type="text" name="SOURCE" id="MERGE4" placeholder="How" value={source}  onChange={(e)=>setSource(e.target.value)}/>
                        <label>HOW DID YOU FIND OUT ABOUT US?</label>
                    </div>
                    <div className="form-field application-field">
                        <textarea name="WHY" id="MERGE8" rows={1} cols={1} value={why} maxLength={255} onChange={ (e)=>setWhy(e.target.value) } />
                        <label>WHAT WOULD MAKE YOU A GOOD FIT? (Character limit 255)</label>
                    </div>

                    {/* <div className="form-field application-field">
                        <ul>
                            <p>Cities you can volunteer in?*</p>
                                <li><input type="checkbox" className="event" value="1" name="group[137][1]" id="mce-group[137]-137-0" /><span>Los Angeles</span></li>
                                <li><input type="checkbox" className="event" value="2" name="group[137][2]" id="mce-group[137]-137-1" /><span>San Francisco</span></li>
                                <li><input type="checkbox" className="event" value="4" name="group[137][4]" id="mce-group[137]-137-2" /><span>New York</span></li>
                                <li><input type="checkbox" className="event" value="8" name="group[137][8]" id="mce-group[137]-137-3"/><span>Chicago</span></li>
                                <li><input type="checkbox" className="event" value="16" name="group[137][16]" id="mce-group[137]-137-4"/><span>Miami</span></li>
                                <li><input type="checkbox" className="event" value="32" name="group[137][32]" id="mce-group[137]-137-5"/><span>San Diego</span></li>
                                <li><input type="checkbox" className="event" value="4194304" name="group[137][4194304]" id="mce-group[137]-137-6"/><span>Portland</span></li>
                                <li><input type="checkbox" className="event" value="8388608" name="group[137][8388608]" id="mce-group[137]-137-7"/><span>Seattle</span></li>
                                <li><input type="checkbox" className="event" value="16777216" name="group[137][16777216]" id="mce-group[137]-137-8"/><span>New Orleans</span></li>
                        </ul>
                    </div>

                    <div className="form-field application-field">
                        <ul>
                            <p>Which events would you be most interested in?*</p>
                            <li><input type="checkbox" className="event" value="64" name="group[205][64]" id="mce-group[205]-205-0"/><span>Coachella</span></li>
                            <li><input type="checkbox" className="event" value="128" name="group[205][128]" id="mce-group[205]-205-1"/><span>Stagecoach</span></li>
                            <li><input type="checkbox" className="event" value="256" name="group[205][256]" id="mce-group[205]-205-2"/><span>USC Spring Fest</span></li>
                            <li><input type="checkbox" className="event" value="512" name="group[205][512]" id="mce-group[205]-205-3"/><span>Firefly Music Festival</span></li>
                            <li><input type="checkbox" className="event" value="1024" name="group[205][1024]" id="mce-group[205]-205-4"/><span>Desert Daze</span></li>
                            <li><input type="checkbox" className="event" value="2048" name="group[205][2048]" id="mce-group[205]-205-5"/><span>Winter X Games</span></li>
                            <li><input type="checkbox" className="event" value="8192" name="group[205][8192]" id="mce-group[205]-205-6"/><span>Outside Lands Festival</span></li>
                            <li><input type="checkbox" className="event" value="16384" name="group[205][16384]" id="mce-group[205]-205-7"/><span>EDC LV</span></li>
                            <li><input type="checkbox" className="event" value="524288" name="group[205][524288]" id="mce-group[205]-205-8"/><span>Camp Flog Gnaw</span></li>
                            <li><input type="checkbox" className="event" value="1048576" name="group[205][1048576]" id="mce-group[205]-205-9"/><span>DesignerCon</span></li>
                            <li><input type="checkbox" className="event" value="2097152" name="group[205][2097152]" id="mce-group[205]-205-10"/><span>Waterfest</span></li>
                            <li><input type="checkbox" className="event" value="33554432" name="group[205][33554432]" id="mce-group[205]-205-11"/><span>Arroyo Seco Weekend</span></li>
                            <li><input type="checkbox" className="event" value="67108864" name="group[205][67108864]" id="mce-group[205]-205-12"/><span>Kappa Futur Festival</span></li>
                            <li><input type="checkbox" className="event" value="134217728" name="group[205][134217728]" id="mce-group[205]-205-13"/><span>New Orleans Jazz Festival</span></li>
                        </ul>
                    </div> */}

                    <div className="form-field application-field">
                        <ul>
                            <p>Skills and experience*</p>
                            <li><input type="checkbox" className="skills" value="268435456" name="group[265][268435456]" id="mce-group[265]-265-0"/><span>Social Media</span></li>
                            <li><input type="checkbox" className="skills" value="536870912" name="group[265][536870912]" id="mce-group[265]-265-1"/><span>Photography</span></li>
                            <li><input type="checkbox" className="skills" value="1073741824" name="group[265][1073741824]" id="mce-group[265]-265-2"/><span>Video</span></li>
                            <li><input type="checkbox" className="skills" value="2147483648" name="group[265][2147483648]" id="mce-group[265]-265-3"/><span>Video Editing</span></li>
                            <li><input type="checkbox" className="skills" value="4294967296" name="group[265][4294967296]" id="mce-group[265]-265-4"/><span>Festival Production Experience</span></li>
                            <li><input type="checkbox" className="skills" value="8589934592" name="group[265][8589934592]" id="mce-group[265]-265-5"/><span>Adobe Creative Cloud</span></li>
                            <li><input type="checkbox" className="skills" value="17179869184" name="group[265][17179869184]" id="mce-group[265]-265-6"/><span>Environmental Major or Professional</span></li>
                            <li><input type="checkbox" className="skills" value="34359738368" name="group[265][34359738368]" id="mce-group[265]-265-7"/><span>Electrical Engineering</span></li>
                            <li><input type="checkbox" className="skills" value="68719476736" name="group[265][68719476736]" id="mce-group[265]-265-8"/><span>Accounting</span></li>
                            <li><input type="checkbox" className="skills" value="274877906944" name="group[265][274877906944]" id="mce-group[265]-265-10"/><span>A/V Experience</span></li>
                            <li><input type="checkbox" className="skills" value="549755813888" name="group[265][549755813888]" id="mce-group[265]-265-11"/><span>Camping and Outdoor Skills</span></li>
                            <li><input type="checkbox" className="skills" value="1099511627776" name="group[265][1099511627776]" id="mce-group[265]-265-12"/><span>Fine Art Skills</span></li>
                            <li><input type="checkbox" className="skills" value="2199023255552" name="group[265][2199023255552]" id="mce-group[265]-265-13"/><span>Exprience Working Events/Festivals</span></li>
                            <li><input type="checkbox" className="skills" value="137438953472" name="group[265][137438953472]" id="mce-group[265]-265-9"/><span>Other</span></li>
                        </ul>
                    </div>

                    <div className="submit-container">
                        <button type="submit" className="btn-container btn primary">
                            <span>SUBMIT</span>
                        </button>
                    </div>

                    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true' aria-label="Please leave the following three fields empty">
                        <label htmlFor="b_name">Name: </label>
                        <input type="text" name="b_name" tabIndex={-1} value="" placeholder="Freddie" id="b_name"/>

                        <label htmlFor="b_email">Email: </label>
                        <input type="email" name="b_email" tabIndex={-1} value="" placeholder="youremail@gmail.com" id="b_email"/>

                        <label htmlFor="b_comment">Comment: </label>
                        <textarea name="b_comment" tabIndex={-1} placeholder="Please comment" id="b_comment"></textarea>
                    </div>

                    <div className={`contact-message ${feedback.messageType}`} dangerouslySetInnerHTML={{__html: feedback.message}} />
                </form>
            </div>
        </div>
        </div>
    </div>
    </div>
    </Layout>
)
}

export default SignUpVolunteer;
